import { createAuth0Client } from '@auth0/auth0-spa-js';
import HotelRankings from './hotel-rankings/HotelRankings.svelte';


if (typeof window.AbortController === 'undefined') {
    class AbortControllerPolyfill {
        constructor() {
            this.signal = { aborted: false, addEventListener: () => { }, removeEventListener: () => { } };
        }
        abort() {
            this.signal.aborted = true;
        }
    }
    window.AbortController = AbortControllerPolyfill;
}

if (!Promise.prototype.finally) {
    Promise.prototype.finally = function (callback) {
        return this.then(
            value => Promise.resolve(callback()).then(() => value),
            reason => Promise.resolve(callback()).then(() => { throw reason; })
        );
    };
}

function bootstrapSwitch() {
    const sheets = [...document.getElementsByTagName('link')];

    const bootstrapThree = sheets.find((sheet) => {
        const href = sheet.getAttribute('href');
        return href.includes('3.1.1');
    });

    if (bootstrapThree) bootstrapThree.setAttribute('disabled', true);

    const bootstrapFive = sheets.find((sheet) => {
        const href = sheet.getAttribute('href');
        return href.includes('5.1.1');
    });

    if (bootstrapFive) bootstrapFive.removeAttribute('disabled');
}

const hotelRankings = () => {
    bootstrapSwitch();

    new HotelRankings({
        target: document.body,
    });
};

const clients = {
    'localhost:7622': 'qboKZIqETnJYhzg3Vf6Et0pGGQFpl8SA',
    'localhost:3002': 'qboKZIqETnJYhzg3Vf6Et0pGGQFpl8SA',
    'lightning-config-api-dev': 'nO8p9nMxTCGk9Y6FyWH7WhVAMbBKcAW6',
    'lightning-config-api-qa': 'qboKZIqETnJYhzg3Vf6Et0pGGQFpl8SA',
    'lightning-config-qasab': 'qboKZIqETnJYhzg3Vf6Et0pGGQFpl8SA',
    'config-staging': 'TVe5yzQdY3NGANM7vdnsh8riYrTAIWrl',
    'lightning-config-api-a-prod-preprod': 'J2UXeTuzdTZ4dKJkJ2Dv6FOo3KGajY78',
    config: 'J2UXeTuzdTZ4dKJkJ2Dv6FOo3KGajY78'
};

const domains = {
    'localhost:7622': 'travelctm-au-qa.au.auth0.com',
    'localhost:3002': 'travelctm-au-qa.au.auth0.com',
    'lightning-config-api-dev': 'travelctm-au-development.au.auth0.com',
    'lightning-config-api-qa': 'travelctm-au-qa.au.auth0.com',
    'lightning-config-qasab': 'travelctm-au-qa.au.auth0.com',
    'config-staging': 'travelctm-au-staging.au.auth0.com',
    'lightning-config-api-a-prod-preprod': 'travelctm-au-production.au.auth0.com',
    config: 'travelctm-au-production.au.auth0.com'
};

const host = location.host.split('.')[0];
const auth_client = clients[host];
const auth_domain = domains[host];


createAuth0Client({
    domain: auth_domain,
    clientId: auth_client,
    leeway: 60,
    authorizationParams: {
        redirect_uri: location.protocol + '//' + location.host,
        audience: 'http://config',
        scope: 'openid email profile',
    },
    cacheLocation: 'localstorage',
}).then(async (client) => {

    if (window.location.search.includes('error')) {
        await client.logout({
            returnTo: window.location.origin
        });
    }

    if (window.location.search.includes('state')) {
        await client.handleRedirectCallback(window.location.origin + window.location.search);
    }

    const isAuthenticated = await client.isAuthenticated();

    if (isAuthenticated) {
        window.auth0Client = client;
        const token = await client.getTokenSilently();
        const user = await client.getUser();

        let oauth = {
            from: 'auth0',
            refresh_token: null,
            access_token: token,
            fullname: user.name,
            username: user.nickname,
        };

        if (localStorage.getItem('oauth')) {
            localStorage.setItem('oauth', JSON.stringify(oauth));
        } else {
            localStorage.setItem('oauth', JSON.stringify(oauth));
            window.location.href = '/#/debtors';
        }
    } else {
        await client.loginWithRedirect({
            authorizationParams: {
                redirect_uri: location.protocol + '//' + location.host,
            }
        });
    }
}).catch((e) => {
    console.error(e);
    localStorage.removeItem('oauth');
    if (window.auth0Client) {
        window.auth0Client.logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        })
    }
});

if (!navigator.userAgent.includes('Firefox/56')) {
    window.svelte = { hotelRankings };
}
